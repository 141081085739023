@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


/* :root {
  --primaryCol: #141414;
  --secondaryCol: #2DC7FF;
  --textCol1: white;
  --textCol2: #D3D3D3;
  --fontPrimary: 'Poppins', sans-serif;
  --loading-grey: #ededed;
  --engineeringCard: #ffffff1a;
  --engineeringCard-hover: #ffffff3a;
  --engineeringSkill: #2dc7ff1a;
  --headshotCol: #2DC7FF;
} */

:root {
  --primaryCol: #F5F5F5;
  --secondaryCol: #2DC7FF;
  --textCol1: black;
  --textCol2: #999999;
  --fontPrimary: 'Poppins', sans-serif;
  --loading-grey: #ededed;
  --engineeringCard: #eaeaea;
  --engineeringCard-hover: #2dc7ff1a;
  --engineeringSkill: #2dc7ff3a;
  --headshotCol: #2DC7FF;
  --hamburgerCol: #ebebeb;
}

/* Base Stuff*/
.debug {
  outline: 1px solid red;
}
.debug1 {
  outline: 1px solid greenyellow;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
li::marker {
  content: '- ';
  font-size: 1.2em;
}
.App-background {
  background-color: var(--primaryCol) !important;
  min-height: 100vh;
  }
.engineering-loading-animation {
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: var(--engineeringCard);
  animation: simmer-loading 1s linear infinite alternate;
}
@keyframes engineering-loading-animation {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@keyframes simmer-loading {
  0% {
    background-color: rgba(255,255,255, 0.04);;
  }
  50% {
    background-color: rgba(255,255,255, 0.1);;
  }
  100% {
    background-color: rgba(255,255,255, 0.04);;
  }
}

.image-wrapper {
  height: 100%;
}

/* Text */
.text-header-jumbo {
  font-size: 40px;
  color: var(--secondaryCol);
  font-family: var(--fontPrimary);
  margin-top: 1vw;
}
.text-header-big {
  font-size: 35px;
  color: var(--textCol1);
  font-family: var(--fontPrimary);
}
.text-subtitle {
  font-size: 16px;
  color: var(--textCol1);
  font-family: var(--fontPrimary);
  margin-top: 1vw;
}

/* Homepage */
.home-format {
  margin: auto;
  margin-left: 5vw;
  margin-right: 5vw;
  justify-content: center;
  margin-top: 5vw;
  opacity:0;
  animation: smooth-appear 1s ease forwards;
}
.home-row {
  justify-content: center;
  height: 35vw;
}

@keyframes smooth-appear {
  to{
    opacity:1;
  }
}

.home-col2 {
  min-width: 400px !important;
  height: 350px;
  align-content: center;
}

@media (max-width: 991px) {
  .home-col2 {
    text-align: center;
  }
}

.home-col1 {
  /* width: 50% !important; */
  height: 350px;
}
.home-picture {
  max-height: 350px;
  width: auto;
  max-width: 100%;
  /* width:300px;
  height:300px; */
  border-radius: 9999px;
  border-style: solid;
  border-width: 5px;
  border-color: var(--headshotCol);
  /* background-color: var(--secondaryCol); */
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.home-button {
  background-color: transparent !important;
  color: var(--secondaryCol) !important;
  border-radius: 30px !important;
  border: .1rem solid var(--secondaryCol) !important;
  padding-left: 1rem !important;
  padding-right: 1rem!important;
  font-size: 16px !important;
  font-family: var(--fontPrimary) !important;
  margin-top: 2rem;
  /* Transition Stuff */
  box-shadow: inset 0 0 0 0 var(--secondaryCol);
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.home-button:hover{
  background-color: var(--secondaryCol) !important;
  color: var(--primaryCol) !important;
  border: 5px solid white;
  /* Transition Stuff */
  box-shadow: inset 400px 0 0 0 var(--secondaryCol);
  transition: ease-out 0.5s;
}

/* Contact Page */
.contact-row {
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  opacity:0;
  animation: smooth-appear 1s ease forwards;
}
.contact-title {
  font-size: 40px;
  color: var(--secondaryCol);
  font-family: var(--fontPrimary);
  margin-bottom: 20px;
}
.contact-header {
  font-size: 16px;
  color: var(--textCol2);
  font-family: var(--fontPrimary);
  margin-bottom: 10px;
  color: var(--textCol1);
}
.contact-general {
  background-color: var(--engineeringCard);
  max-width: 300px;
  border-radius: 8px !important;
  border: .1rem solid var(--secondaryCol) !important;
  margin-bottom: 20px;
  color: var(--textCol1);
}
.contact-message {
  background-color: var(--engineeringCard);
  max-width: 100%;
  border-radius: 8px !important;
  border: .1rem solid var(--secondaryCol) !important;
  margin-bottom: 20px;
  color: var(--textCol1);
}
.contact-submit {
  background-color: transparent !important;
  color: var(--secondaryCol) !important;
  border-radius: 30px !important;
  border: .1rem solid var(--secondaryCol) !important;
  padding: 5px;
  font-size: 16px !important;
  font-family: var(--fontPrimary) !important;
  width: 90px;
  margin-top: 5px;
  /* Transition Stuff */
  box-shadow: inset 0 0 0 0 var(--secondaryCol);
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.contact-submit:hover{
  background-color: var(--secondaryCol) !important;
  color: var(--primaryCol) !important;
  border: 5px solid var(--textCol1);
  /* Transition Stuff */
  box-shadow: inset 400px 0 0 0 var(--secondaryCol);
  transition: ease-out 0.5s;
}
.contact-recieved {
  font-size: 16px;
  color: var(--textCol2);
  font-family: var(--fontPrimary);
  margin-top: 10px;
  color: var(--textCol1);
  opacity:0;
  animation: smooth-appear 2s ease forwards;
}
.contact-project-link {
  color: var(--secondaryCol) !important;
}
.contact-project-link:hover {
  color: var(--textCol1) !important;
}

/* Engineering Page */
.engineering-format {
  margin: auto;
  margin-left: 15px;
  margin-right: 15px;
  justify-content: center;
  text-align: center;
  margin-top: 5vw;
  opacity:0;
  animation: smooth-appear 1s ease forwards;
}
.engineering-row {
  justify-content: center;
}
.engineering-entry {
  max-width: min(70vw,25rem) !important;
  /* max-width: 30rem !important; */
  /* background-color: rgba(255,255,255, 0.04); */
  background-color: var(--engineeringCard);
  border-radius: 30px;
  padding: 0 !important;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  transition: all 300ms ease-in-out;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.engineering-entry:hover {
  background-color: var(--engineeringCard-hover);
  /* animation: skeleton-loading 1s linear infinite alternate; */
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.engineering-entry-content {
  margin: min(3vw,30px);
}
.engineering-entry-image-container {
  /* height: 20rem; */
  height: min(40vw,15rem);
  position: relative;
  justify-content: center;
}
.engineering-image {
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border-radius: 20px; */
}
.engineering-title {
  margin-top: 10px;
  font-size: min(5vw,1.7rem);
  text-align: center;
  color: var(--textCol1);
}
.engineering-line {
  margin: 0.5em auto;
  width: 80%;
  color: black;
}
.engineering-goals {
  font-size: min(3.5vw,1rem);
  text-align: start;
  color: var(--textCol2);
}
a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}
.engineering-skill-row {
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  padding-left:10px;
}
.engineering-skill {
  font-size: min(3.5vw,1rem);;
  color: var(--secondaryCol);
  background-color: var(--engineeringSkill);
  border-radius: 9999px;
  border-width: 3px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 10px;
  margin-bottom: 10px;
}

/* Individual Project Page */
.project-format {
  margin: auto;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: center;
  text-align: center;
  margin-top: 4vw;
  opacity:0;
  animation: smooth-appear 1s ease forwards;
}
.project-row {
  justify-content: center;
}
.project-title {
  font-size: min(6vw,2.5rem);
  color: var(--secondaryCol);
  font-family: var(--fontPrimary);
  text-align: center;
}
.project-subheader {
  margin-top: min(2.5vw,20px);
}
.project-info {
  font-size: min(4vw,1.5rem);
  color: var(--secondaryCol);
  font-family: var(--fontPrimary);
  display: inline;
}
.project-subtitle {
  font-size: min(4vw,1.5rem);
  color: var(--secondaryCol);
  font-family: var(--fontPrimary);
  text-align: start;
  font-weight: bold;
}
.project-text {
  font-size: min(3vw,1rem);
  text-align: start;
  color: var(--textCol2);
  font-family: var(--fontPrimary);
  margin-top: 5px;
}
.project-pill{
  padding-top: min(2.5vw,20px);
  max-width: 800px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content:start;
  gap: min(1vw,20px);
}
.project-pill-objectives {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}
.project-pill-skills {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}
.project-line {
  color: var(--secondaryCol);
  width: 80%;
  max-width: 50rem;
}
.project-image-row{
  padding-top: min(5vw,20px);
  /* max-width: 40vw; */
  max-width: 800px;
}
.project-image {
  width: auto;
  max-width: 100%;
  /* min-height: 300px; */
  object-fit: contain;
  margin-bottom: 2rem;
  /* border: 2px solid;
  border-radius: 20px; */
  padding: 0 !important;
  border-color: var(--secondaryCol);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.project-loading-animation {
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  height: 300px;
  width: 800px;
  border: 2px solid;
  border-radius: 20px;
  padding: 10px;
  border-color: var(--secondaryCol);
  background-color: var(--engineeringCard);
  animation: simmer-loading 1s linear infinite alternate;
}
@keyframes project-loading-animation {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/*Model Page*/
.model-format {
  margin: auto;
  margin-left: 15px;
  margin-right: 15px;
  justify-content: center;
  text-align: center;
  margin-top: 2vw;
  margin-bottom: 5rem;
  opacity:0;
  animation: smooth-appear 1s ease forwards;
}
.model-row {
  justify-content: center;
  max-width: 500px !important;
}
.model-image {
  width: 100%;
  max-width: 100%;
  min-height: 300px;
  object-fit: contain;
  transition: all 300ms ease-in-out;
}

.image-loading {
  opacity: 0;
  visibility: hidden;
}

.image-loaded {
  opacity: 1;
  visibility: visible;
}

.model-loading-animation {
  width: 100%;
  height: 300px;
  background-color: var(--engineeringCard);
  animation: simmer-loading 1s linear infinite alternate;
  margin-bottom: 2rem;
}

.model-image-container {
  position: relative;
  margin: 5px 0;
  padding: 0 !important;
}
.model-image-text {
  position: absolute;
  align-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-style: italic;
  color: white;
  width: 100%;
  height: 100%;
  transition: all 300ms ease-in-out;
  /* padding: 5px 10px;
  border-radius: 5px;
  display: none; */
}
.model-image-text:hover {
  background-color: black;
  opacity: 0.65;
}

.image-container:hover .image-text,
.image-text:hover {
    display: block;
}

.model-measurements {
  min-height:5rem;
  width: auto;
  height: auto;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0;
  font-size: min(12px,3vw);
  padding-left: 10vw;
  padding-right: 10vw;
  color: var(--textCol1);
  background-color: var(--primaryCol);
  /* box-shadow: 0 0 20px 20px var(--primaryCol); */
}
.model-line {
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 30vw;
  color: var(--textCol1);
}

/* Travel */
.mapContainer {
  width: 800px;
  height: 600px;
  margin-top: 20px;
  background-color: black;
  border: 2px solid var(--secondaryCol);
}
.worldMap {
  fill: rgba(45,199,255, 0.1);
  stroke: rgba(45,199,255, 0.1);
  outline: none;
}
.mapMarker {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.mapMarker:hover {
  background-color: white;
}

/* Nav Bar */
.navVertAlign {
  align-items: center;
}
.navLogo {
  margin-left: 4.5vw;
  margin-right: 2rem;
}
.navName {
  color: var(--textCol1);
  font-family: var(--fontPrimary);
  font-size: 25px;
  transition: ease-in-out 300ms;
}
.navName:hover {
  color: var(--secondaryCol);
}
.nav-hamburger{
  margin-right: 4.5vw;
  background-color: var(--hamburgerCol) !important;

  transition: all 300ms ease-in-out;
}
.nav-hamburger:hover {
  background-color: var(--engineeringSkill) !important;
}
.navElements {
  justify-content: end;
  margin-right: 5rem;
}
.navElement {
  color: var(--textCol2);
  font-family: var(--fontPrimary);
  font-size: 15px;
  margin-left: 2rem;
  margin-right: 2rem;
  transition: ease-in-out 300ms;
}
.navElement:hover {
  color: var(--secondaryCol);
}
.navButton {
  background-color: transparent !important;
  color: var(--secondaryCol) !important;
  border-radius: 30px !important;
  border: .1rem solid var(--secondaryCol) !important;
  padding-left: 1rem !important;
  padding-right: 1rem!important;
  font-size: 1rem !important;
  /* font-weight: bold !important; */
  font-family: var(--fontPrimary) !important;
  /* Transition Stuff */
  box-shadow: inset 0 0 0 0 var(--secondaryCol);
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.navButton:hover {
  background-color: var(--secondaryCol) !important;
  color: var(--primaryCol) !important;
  border: 5px solid white;
  /* Transition Stuff */
  box-shadow: inset 400px 0 0 0 var(--secondaryCol);
  transition: ease-out 0.5s;
}

.agency-portfolio-button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-family: var(--fontPrimary);
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 500px !important;
}

.agency-portfolio-button:hover {
    background-color: #666666;
    cursor: pointer;
}